<template>
  <b-form @submit.prevent="submitForm()" class="cert-container" ref="form">
    <div class="flex-center davai-pogovorim-container">
      <span class="davai-pogovorim">Оформить Сертификат</span>
    </div>
    <label class="mt-4">Сертификат</label>
    <b-form-select
      v-model="form.certificate"
      :options="certOptions"
      :disabled="isLoading"
      placeholder="Выбирите сертификат"
      required
      class="mt-2"
    ></b-form-select>
    <label class="mt-4">Способ связи</label>
    <b-form-radio-group
      v-model="form.contact"
      :options="contactOptions"
      :disabled="isLoading"
      name="radio-options"
    ></b-form-radio-group>
    <template v-if="form.contact == 'phone'">
      <label class="mt-4">Ваш номер телефона</label>
      <b-form-input
        placeholder="+375 (__) ___-__-__"
        v-model="form.phone"
        :disabled="isLoading"
        :required="form.contact == 'phone'"
      ></b-form-input>
    </template>
    <template v-if="form.contact == 'email'">
      <label class="mt-4">Ваш Email</label>
      <b-form-input
        placeholder="example@mail.ru"
        type="email"
        v-model="form.email"
        :disabled="isLoading"
        :required="form.contact == 'email'"
      ></b-form-input>
    </template>
    <template v-if="form.contact == 'viber'">
      <label class="mt-4">Ваш Viber</label>
      <b-form-input
        placeholder="+375 (__) ___-__-__"
        v-model="form.viber"
        :disabled="isLoading"
        :required="form.contact == 'viber'"
      ></b-form-input>
    </template>
    <template v-if="form.contact == 'telegram'">
      <label class="mt-4">Ваш Telegram</label>
      <b-form-input
        placeholder="Введите ссылку на Telegram"
        v-model="form.telegram"
        :disabled="isLoading"
        :required="form.contact == 'telegram'"
        type="text"
      ></b-form-input>
    </template>
    <template v-if="form.contact == 'instagram'">
      <label class="mt-4">Ссылка на Intagram</label>
      <b-form-input
        placeholder="Ссылка на Intagram"
        v-model="form.instagram"
        :disabled="isLoading"
        :required="form.contact == 'instagram'"
        type="text"
      ></b-form-input>
    </template>
    <template v-if="form.contact == 'another'">
      <label class="mt-4">Другой способ связи</label>
      <b-form-input
        placeholder="Введите другой способ связи"
        v-model="form.another"
        :disabled="isLoading"
        :required="form.contact == 'another'"
        type="text"
      ></b-form-input>
    </template>
    <label class="mt-4">Ваше имя</label>
    <b-form-input
      placeholder="Введите имя"
      type="text"
      required
      v-model="form.name"
    ></b-form-input>
    <template v-if="isLoading">
      <div class="flex-center">
        <b-spinner style="width: 40px; height: 40px"></b-spinner>
      </div>
    </template>
    <template v-if="isShowSuccessMessage">
      <div class="flex-center mt-4">
        <h3>Спасибо, ваша заявка принята!</h3>
      </div>
    </template>
    <div class="flex-center mt-4">
      <button type="submit" class="btn btn-contact" :disabled="isLoading">
        Оформить
      </button>
    </div>
  </b-form>
</template>

<script>
import "./CertForm.scss";
import MainService from "@/services/main-service/main.service";
export default {
  name: "CertForm",
  props: {
    certOptionsList: {
      type: Array,
      default: [],
      required: true,
    },
    certSelected: {
      type: String,
      default: "",
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.certOptions = this.certOptionsList.map((item) => {
      return {
        value: item.price,
        text: item.title + " на " + item.price,
      };
    });
    console.log(this.certSelected);
    this.form.certificate = this.certSelected ?? null;
  },
  data() {
    return {
      contactOptions: [
        { value: "phone", text: "Звонок" },
        { value: "telegram", text: "Telegram" },
        { value: "viber", text: "Viber" },
        { value: "email", text: "Email" },
        { value: "instagram", text: "Instagram" },
        { value: "another", text: "Другое" },
      ],
      certOptions: [],
      isLoading: false,
      isShowSuccessMessage: false,
      form: {
        type: "Заяка на оформление сертификата",
        name: "",
        contact: "phone",
        phone: "",
        email: "",
        telegram: "",
        email: "",
        instagram: "",
        another: "",
        certificate: "",
      },
    };
  },
  methods: {
    async submitForm() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.presentLoading();
      try {
        const { status } = await MainService.sendEmail(this.form);
        if (status === "Success") {
          this.showSuccessMessage();
        } else {
          this.makeToast(
            "warning",
            "Заяка с сайта!",
            "Упс, что-то пошло не так!"
          );
          this.stopLoading();
        }
      } catch (error) {
        console.log(error);
        this.makeToast(
          "error",
          "Заяка с сайта!",
          error.message ?? "Ошибка сервера!"
        );
        this.stopLoading();
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    showSuccessMessage() {
      this.isShowSuccessMessage = true;
      setTimeout(() => {
        this.isShowSuccessMessage = false;
        this.stopLoading();
        this.closeModal();
      }, 3000);
    },
    presentLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    makeToast(variant = null, title, content) {
      this.$bvToast.toast(`${content}`, {
        title: `${title}`,
        variant: variant,
        solid: true,
      });
    },
    resetForm() {
      this.form = {
        type: "Заяка на оформление сертификата",
        name: "",
        contact: "phone",
        phone: "",
        email: "",
        telegram: "",
        email: "",
        instagram: "",
        another: "",
        certificate: "",
      };
    },
  },
};
</script>
