import Axios from "../axios-instance/axios.service";

const API_URL =
  process.env.NODE_ENV !== "development"
    ? `${window.location.origin}/api/v1`
    : "http://localhost:5000/api/v1";

class MainService {
  async sendEmail(body) {
    const { data } = await Axios.post(`${API_URL}/send-email`, body);
    return data;
  }
}

export default new MainService();
