<template>
  <div>
    <header>
      <!-- Меню -->
      <div v-if="!mobileMenuOpen" class="burger" @click="openMobileMenu()">
        <img
          class="burger__item img-fluid"
          src="@/assets/menu.svg"
          alt="Окрыть меню"
        />
      </div>
      <nav
        v-else
        class="toolbar"
        :class="mobileMenuOpen ? 'toolbar__show' : ''"
      >
        <img
          class="burger__item img-fluid toolbar__close"
          src="@/assets/close.svg"
          alt="Закрыть меню"
          @click="openMobileMenu()"
        />
        <img
          class="img-fluid toolbar__logo"
          src="@/assets/logo.png"
          alt="Логотип"
          @click="toSlide(0)"
        />
        <button
          @click="toSlide(1)"
          class="toolbar__link"
          :class="curremtIndex == 1 ? 'active' : ''"
        >
          Почему я?
        </button>
        <button
          @click="toSlide(2)"
          class="toolbar__link"
          :class="curremtIndex == 2 ? 'active' : ''"
        >
          Портфолио
        </button>
        <button
          @click="toSlide(3)"
          class="toolbar__link"
          :class="curremtIndex == 3 ? 'active' : ''"
        >
          Эскизы
        </button>
        <button
          @click="toSlide(4)"
          class="toolbar__link"
          :class="curremtIndex == 4 ? 'active' : ''"
        >
          Обо мне
        </button>
        <button
          @click="toSlide(5)"
          class="toolbar__link"
          :class="curremtIndex == 5 ? 'active' : ''"
        >
          Калькулятор
        </button>
        <button
          @click="toSlide(6)"
          class="toolbar__link"
          :class="curremtIndex == 6 ? 'active' : ''"
        >
          FAQ
        </button>
        <button
          @click="toSlide(7)"
          class="toolbar__link"
          :class="curremtIndex == 7 ? 'active' : ''"
        >
          Сертификаты
        </button>
        <button
          @click="toSlide(8)"
          class="toolbar__link"
          :class="curremtIndex == 8 ? 'active' : ''"
        >
          Запись
        </button>
      </nav>
      <div class="modal" :class="isModalOpen ? 'modal__show' : ''">
        <img
          class="modal__item img-fluid modal__close"
          src="@/assets/close.svg"
          alt="Modal Close"
          @click="closeModalGetGift()"
        />
        <CertForm
          v-if="isModalOpen"
          :cert-options-list="certificatesList"
          :cert-selected="certSelected"
          :close-modal="closeModalGetGift"
        />
      </div>
    </header>
    <main>
      <swiper
        :options="swiperOption"
        ref="swiper"
        class="swiper"
        @slideChange="onSlideChange()"
      >
        <!--Видео Секция-->
        <swiper-slide>
          <video-background
            class="f-section"
            id="home"
            ref="firstSection"
            :src="require('@/assets/video.mp4')"
          >
            <b-container>
              <b-row>
                <b-col>
                  <div class="flex-center main-text">
                    <h1>VLADHELLA</h1>
                    <h2>Tattoo-master</h2>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </video-background>
        </swiper-slide>

        <!--Обо мне-->
        <swiper-slide>
          <section class="section about-section" id="about-us">
            <div class="background">
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
            </div>
            <div class="right-title">
              <img
                src="@/assets/h2-img/h2-bg.png"
                class="about-img"
                alt="Почему я?"
              />
              <h2>Почему я?</h2>
            </div>
            <b-container>
              <b-row>
                <div class="col-12 offset-lg-4 col-lg-8">
                  <div class="about-section__right">
                    <div
                      class="item"
                      v-for="(item, index) in whyMeList"
                      :key="index"
                    >
                      Более
                      <span>{{ item.value }}</span>
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <!--Портфолио-->
        <swiper-slide>
          <section class="section portfolio-section" id="portfolio">
            <div class="left-title">
              <img
                src="@/assets/h2-img/h2-bg-black.png"
                class="portfolio-img"
              />
              <h2>Портфолио</h2>
            </div>
            <b-container>
              <b-row class="neon">
                <div
                  class="col-4 col-lg-3"
                  v-for="(portfolio, index) in portfolioList"
                  :key="index"
                  v-viewer="{ movable: false }"
                >
                  <div class="photo mb-4 mt-4">
                    <img
                      class="img-fluid image-gallery"
                      :src="portfolio.image"
                      :alt="portfolio.alt"
                    />
                  </div>
                </div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <!--Эскизы-->
        <swiper-slide>
          <section class="section eskiz-section" id="eskiz">
            <div class="right-title">
              <img src="@/assets/h2-img/h2-bg.png" class="eskiz-img" />
              <h2>Эскизы</h2>
            </div>
            <b-container>
              <b-row class="neon">
                <div
                  class="col-4 col-lg-3"
                  v-for="(portfolio, index) in eskizList"
                  :key="index"
                  v-viewer="{ movable: false }"
                >
                  <div class="photo mb-4 mt-4">
                    <img
                      class="img-fluid image-gallery"
                      :src="portfolio.image"
                      :alt="portfolio.alt"
                      :key="index"
                    />
                  </div>
                </div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <!--Почему я-->
        <swiper-slide>
          <section class="section why-section" id="why">
            <div class="left-title">
              <img
                src="@/assets/h2-img/h2-bg-black.png"
                class="why-img"
                alt="Обо мне"
              />
              <h2>Обо мне</h2>
            </div>
            <b-container>
              <b-row>
                <div class="col-12 col-lg-8">
                  <div class="why-section__right mt-3">
                    <b-row>
                      <p>
                        Добро пожаловать в мой мрачный мир татуировок. Хочу
                        сказать, что Мне нравится познавать эту жизнь через
                        татуировку. Именно поэтому На протяжении 4 лет я
                        занимаюсь созданием чего-то большего чем рисунок на
                        теле. Я занимаюсь созданием бессмертия.
                      </p>
                      <p><b>Вы удивлены не так ли?</b></p>
                      <p>
                        Я стремлюсь оставить след после себя через искусство
                        татуирования. Жизнь очень скоротечна и я понимаю, что в
                        любой момент меня может не стать, но мое искусство
                        останется на ваших телах вечно напоминая о моем
                        присутствии в этом мире. Эта мысль меня очень
                        вдохновляет.
                      </p>
                      <p>
                        И далеко не секрет, что Татуировка это то, что останется
                        навсегда, поэтому я подхожу очень серьезно к созданию
                        вечного. Мне очень нравится, что мы все настолько
                        разные, с разной историей и разными взглядами на мир, но
                        всех нас объединяет одно…
                      </p>
                      <p>
                        Любовь к татуировкам. В каждый миллиметр своих работ я
                        вкладываю всю свою душу! В каждой работе есть частичка
                        меня.
                      </p>
                      <p><b>Не веришь?</b></p>
                      <p>
                        Тогда Проверь сам! мои татуировки смогут сказать тебе
                        то, о чем не расскажет ни одно другое искусство.
                      </p>
                    </b-row>
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <img
                    class="img-fluid"
                    src="@/assets/why-me.png"
                    alt="Обо мне"
                  />
                </div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <!--Калькулятор-->
        <swiper-slide>
          <section class="section calculator-section" id="calculator">
            <div class="left-title">
              <img
                src="@/assets/h2-img/h2-bg.png"
                class="calculator-img"
                alt="Калькулятор"
              />
              <h2>Калькулятор</h2>
            </div>
            <b-container>
              <Calculator />
            </b-container>
          </section>
        </swiper-slide>
        <!--FAQ-->
        <swiper-slide>
          <section class="section faq-section" id="faq">
            <div class="right-title">
              <img
                src="@/assets/h2-img/h2-bg-black.png"
                class="faq-img"
                alt="FAQ"
              />
              <h2>FAQ</h2>
            </div>
            <b-container>
              <b-row>
                <div class="col-12 col-lg-12">
                  <div
                    v-for="(faq, index) in faqList"
                    class="faq-item"
                    :key="index"
                  >
                    <div class="faq-item__title" @click="openFaq(faq)">
                      <h3>{{ faq.title }}</h3>
                      <b-icon-dash-circle-fill
                        v-if="faq.isOpen"
                      ></b-icon-dash-circle-fill>
                      <b-icon-plus-circle-fill v-else></b-icon-plus-circle-fill>
                    </div>
                    <div class="faq-item__content" v-if="faq.isOpen">
                      <p v-html="faq.answer"></p>
                    </div>
                  </div>
                </div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <!--Подарочные Сертификаты-->
        <swiper-slide>
          <section class="section certificates-section" id="certificates">
            <div class="center-title mb-5">
              <img
                src="@/assets/h2-img/h2-bg.png"
                class="certificates-img"
                alt="Подарочные Сертификаты"
              />
              <h2>
                Подарочные <br />
                Сертификаты
              </h2>
            </div>
            <b-container>
              <b-row>
                <div
                  class="col-12 col-lg-4"
                  v-for="(certificate, index) in certificatesList"
                  :key="index"
                >
                  <div class="cert-item">
                    <h3 class="cert-item__title">{{ certificate.title }}</h3>
                    <div class="cert-item__price">{{ certificate.price }}</div>
                    <div class="cert-item__text">
                      <b>{{ certificate.periodText }}</b>
                    </div>
                    <div class="cert-item__text">
                      {{ certificate.otherText }}
                    </div>
                    <button
                      class="btn-second"
                      @click="openModalGetGift(certificate)"
                    >
                      Оформить
                    </button>
                  </div>
                </div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <!--Контакты-->
        <swiper-slide>
          <section class="section contact-section" id="contact">
            <div class="left-title">
              <img
                src="@/assets/h2-img/h2-bg-black.png"
                class="contact-img"
                alt="Запись"
              />
              <h2>Запись</h2>
            </div>
            <b-container>
              <b-row>
                <div class="col-12 col-lg-4">
                  <ContactForm />
                  <div class="social mt-4">
                    <div
                      class="social-item"
                      v-for="(contact, index) in contactsList"
                      :key="index"
                    >
                      <div
                        class="social-item__content"
                        @click="openContactLink(contact.link)"
                      >
                        <img
                          v-if="windowWidth > 992"
                          :src="contact.icon"
                          class="img-fluid"
                        />
                        <img
                          v-else
                          :src="contact.iconMobile"
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex-center">
                    <span class="location">МИНСК-БРЕСТ-ПИНСК</span>
                  </div>
                </div>
                <div class="col-12 col-lg-6"></div>
              </b-row>
            </b-container>
          </section>
        </swiper-slide>
        <template v-slot:button-prev v-if="curremtIndex > 0">
          <img
            src="@/assets/arrow.svg"
            @click="
              $refs.swiper.swiperInstance.slidePrev();
              curremtIndex == $refs.swiper.swiperInstance.activeIndex;
            "
            alt="Вверх"
            class="swiper-button-prev"
          />
        </template>
        <template v-slot:button-next v-if="curremtIndex < 8">
          <img
            src="@/assets/arrow.svg"
            @click="
              $refs.swiper.swiperInstance.slideNext();
              curremtIndex == $refs.swiper.swiperInstance.activeIndex;
            "
            alt="Вниз"
            class="swiper-button-next"
          />
        </template>
      </swiper>
    </main>
  </div>
</template>

<script>
import "./HomePage.scss";
import Calculator from "@/components/calc-component/Calculator.vue";
import ContactForm from "@/components/contact-component/ContactForm.vue";
import CertForm from "@/components/cert-component/CertForm.vue";
import "viewerjs/dist/viewer.css";
// Swiper
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.scss";
export default {
  name: "HomePage",
  components: {
    Calculator,
    Swiper,
    ContactForm,
    CertForm,
    SwiperSlide,
  },
  watch: {
    curremtIndex: function() {
      if (this.curremtIndex === 1) {
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  data() {
    return {
      mobileMenuOpen: false,
      windowWidth: window.innerWidth,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        direction: "vertical",
        speed: 600,
        parallax: false,
        simulateTouch: false,
        touchMoveStopPropagation: true,
        draggable: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        allowTouchMove: false,
        scrollbar: {
          hide: false,
        },
      },
      isModalOpen: false,
      certSelected: "",
      contactsList: [
        {
          title: "Instagram",
          link:
            "https://instagram.com/vladhella?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr",
          icon: require("@/assets/contacts/instagram-icon.svg"),
          iconMobile: require("@/assets/contacts/instagram-icon-mobile.svg"),
        },
        {
          title: "TikTok",
          link: "https://www.tiktok.com/@vladhella?_t=8hmkkS4mXRt&_r=1",
          icon: require("@/assets/contacts/tiktok-icon.svg"),
          iconMobile: require("@/assets/contacts/tiktok-icon-mobile.svg"),
        },
        {
          title: "Telegram",
          link: "https://t.me/vladhella",
          icon: require("@/assets/contacts/telegram.svg"),
          iconMobile: require("@/assets/contacts/telegram-mobile.svg"),
        },
      ],
      certificatesList: [
        {
          title: "Сертификат",
          price: "150р.",
          periodText: "Действует 6 месяцев с момента приобретения.",
          otherText:
            "Возможна доплата если на консультации цена за тату оказалась выше чем номинал сертификата.",
        },
        {
          title: "Сертификат",
          price: "250р.",
          periodText: "Действует 6 месяцев с момента приобретения.",
          otherText:
            "Возможна доплата если на консультации цена за тату оказалась выше чем номинал сертификата.",
        },
        {
          title: "Сертификат",
          price: "500р.",
          periodText: "Действует 6 месяцев с момента приобретения.",
          otherText:
            "Возможна доплата если на консультации цена за тату оказалась выше чем номинал сертификата.",
        },
      ],
      portfolioList: [
        {
          image: require("@/assets/portfolio/1.jpg"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/3.png"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/4.jpg"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/5.jpg"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/6.jpg"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/7.jpg"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/2.jpg"),
          alt: "Портфолио",
        },
        {
          image: require("@/assets/portfolio/8.jpg"),
          alt: "Портфолио",
        },
      ],
      eskizList: [
        {
          image: require("@/assets/eskiz/1.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/2.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/3.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/4.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/6.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/5.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/7.jpg"),
          alt: "Эскиз",
        },
        {
          image: require("@/assets/eskiz/8.jpg"),
          alt: "Эскиз",
        },
      ],
      whyMeList: [
        {
          title: "Клиентов",
          value: "300",
        },
        {
          title: "Тату",
          value: "400",
        },
        {
          title: "Опыта",
          value: "4 лет",
        },
      ],
      faqList: [
        {
          isOpen: false,
          title: "Первая татуировка. С чего начать?",
          answer:
            "Погрузись в мир тайн и загадок, выбрав концепцию и стиль, который будет олицетворять твою особую символику. Просмотрите бескрайние просторы Google, Instagram и Pinterest, чтобы собрать эстетическое насыщение и вдохновение. Размышляй над местом и размером татуировки, чтобы она стала гармоничным украшением твоего тела. Если ты готов, отправь мне сообщение в социальных сетях и поделитесь всей необходимой информацией. Вместе мы создадим неповторимое произведение искусства, которое будет отражать твою индивидуальность и уникальность.",
        },
        {
          isOpen: false,
          title: "Как ухаживать за татуировкой",
          answer: `
            С самого начала, в каждой капле чернил, присутствует таинственность и магия. Забота об этом искусстве является ключом к сохранению его красоты и величия.<br />
            Тайны ухода за татуировкой: <br />
            1. После нанесения татуировки мастер должен наложить защитную повязку, которая оберегает твое искусство от прозаического мира. <br />
            2. Через 2-3 часов, снимите повязку и промойте ее водой с мылом. Поверхность может быть обласкана влажной салфеткой или салфеткой, омоченной в хлоргексидине.<br />
            3. Осторожно, словно прикасаясь к магическим символам, избавь кожу от влаги бумажными полотенцами и нанеси на нее тонкий слой заживляющего крема или мази (БЕПАНТЕН, ДЕКСПАНТЕНОЛ, ДЕКСПАНТЕН). Закройте татуировку бумажным полотенцем или детской пеленкой и зафиксируйте повязку пластырем или трубчатым бинтом. Повторяйте процедуру каждые 3 часа. Спустя время обратите внимание, если на вашей повязке уже не будет следов краски и крови, значит повязка уже не требуется.<br />
            4. Продолжайте мазать татуировку 2-4 раза в сутки в течение 7-15 дней. Этот ритуал необходим для сохранения ее прекрасного вида.<br />
            5. Отказывайтесь от посещения бань, бассейнов и горячих ванн. Эти места не дружественны вашему  шедевру. ( ванны – 2 недели, бани, бассейны и водоёмы – 30 дней )<br />
            6. Защитите свою новую татуировку от вредного солнечного света до конца заживления, позвольте ей наслаждаться тенью и тайной.<br />
            7. Нельзя ковырять, чесать, сдирать корочки. Не подвергайте татуировку агрессии спирта и перекиси. Зуд, возникающий при заживлении, устраняйте легкими похлопываниями по татуировке или поглаживаниями кожи вокруг нее.<br />
            8. Воздержитесь от употребления алкоголя в течение 2-4 дней. Только так твое тело сможет приобщиться к темноте и магия татуировки.
            `,
        },
        {
          isOpen: false,
          title: "Для чего нужна консультация с мастером",
          answer: `
            Будущий клиент, искушенный тайнами искусства, простирает свою руку к дверям, за которыми скрываются мастер татуировки. Эти врата ведут в мир загадок и возможностей, где идеи оживают и становятся воплощением на коже.<br /><br />
            Мастер татуировки - ученый тёмной магии, которая превращает идеи в новую реальность. Он будет разгадывать твои замыслы, проникать в самые глубины твоей души и предлагать свои магические чары на холсте твоего тела. Ведь не  всегда на желаемом месте анатомически возможно выполнить эскиз, поэтому мастер сможет предложить тебе альтернативные варианты, которые будут гармонично вписываться в твоё прекрасное тело.<br /><br />
            Встреча с мастером разрушит витиеватые паутины сомнений и зажжет свет знания. Он обладает ключом к ответам, которые ты ищешь в своем сердце. Очень важно быть уверенным в своем выборе и неспешно рассмотреть все варианты, которые он предложит.<br /><br />
            Консультация, может быть личной или через виртуальное пространство. Рекомендуется проводить устраивать личную встречу. В ходе личной встречи мастер сможет более детально обсудить с тобой все детали и нюансы твоего проекта.<br /><br />
            И так, клиент, раскрой свой размытый эскиз пред Глазом Мастера, и позволь тайнам и загадкам взлететь на крыльях ваших сотрудничества. 
            `,
        },
        {
          isOpen: false,
          title: "Как подготовиться к сеансу?",
          answer: `
            1. Перед сеансом нужно хорошо выспаться (так организму будет проще переносить сеанс).<br />
            2. Обязательно вкусно покушайте (по желанию можно взять что-то с собой).<br />
            3. Ни в коем случае перед сенсом не пить алкоголь, не курить (клиенты в состоянии алкогольного либо наркотического опьянения не обслуживаются).<br />
            4. Перед сеансом принять душ (таким образом наша с вами работа пройдёт с ещё большим комфортом).<br />
            5. Рабочую зону (место для нанесения татуировки) не брить.<br />
            6. Продумайте тщательно во что вы будете одеты. Желательно одевать свободную одежду либо брать с собой сменный набор.<br />
            7. Внимание! Обязательно взять хорошее настроение!
            `,
        },
        {
          isOpen: false,
          title: "Что ещё нужно знать?",
          answer: `Вслед за лунным мраком незнаний, прежде чем изъявить вопросы мастеру татуировки, значение времени стало безмерно. В недрах его инстаграма и мрачных социальных сетей таится магическая ответная пульсация на все скрытые вопросы. А когда вы покорите таинственный барьер, ваш визит преисполнится священной мощью предварительной подготовки.`,
        },
        {
          isOpen: false,
          title: "Что делать если я из другого города?",
          answer: `В основном я работаю городах, таких как Минск, Брест и Пинск. Если ты проживаешь в одном из городов или где-то рядом, поздравляю, тебе очень повезло. Но если нет - не отчаивайся. Я могу посетить любой другой областной город, при условии, что наберется несколько таких же желающих получить татуировку!`,
        },
      ],
      curremtIndex: 0,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerHeight;
    },
    openModalGetGift(cert) {
      this.isModalOpen = true;
      this.certSelected = cert.price;
    },
    closeModalGetGift() {
      this.isModalOpen = false;
      this.certSelected = "";
    },
    openMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    toSlide(index) {
      this.closeMobileMenu();
      this.$refs.swiper.swiperInstance.slideTo(index, 0);
      this.curremtIndex = index;
    },
    openFaq(faq) {
      faq.isOpen = !faq.isOpen;
    },
    onSlideChange() {
      this.curremtIndex = this.$refs.swiper.swiperInstance.activeIndex;
    },
    openContactLink(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
  },
};
</script>
