<template>
  <b-row class="calc-container">
    <div class="col-12 col-lg-6">
      <div class="calculator-section__left flex-center">
        <img src="@/assets/people.png" class="people img-fluid" alt="" />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <b-form @submit.prevent="submitForm()" ref="form">
        <label>Место</label>
        <b-form-select
          v-model="form.place"
          :options="placeOptions"
          :disabled="isLoading"
          placeholder="Выбирите места"
          required
          class="mt-2"
        ></b-form-select>
        <label for="range-height" class="mt-4"
          >Высота (см): {{ form.height }}</label
        >
        <input
          id="range-height"
          v-model="form.height"
          class="range-input"
          type="range"
          min="10"
          max="50"
          step="10"
          :disabled="isLoading"
        />
        <label for="range-with" class="mt-4"
          >Ширина (см): {{ form.width }}</label
        >
        <input
          id="range-with"
          v-model="form.width"
          class="range-input"
          type="range"
          min="5"
          max="25"
          step="5"
          :disabled="isLoading"
        />
        <label class="mt-4">Способ связи</label>
        <b-form-radio-group
          v-model="form.contact"
          :options="contactOptions"
          :disabled="isLoading"
          name="radio-options"
        ></b-form-radio-group>
        <template v-if="form.contact === 'phone'">
          <label class="mt-4">Ваш номер телефона</label>
          <b-form-input
            placeholder="+375 (__) ___-__-__"
            v-model="form.phone"
            :disabled="isLoading"
            :required="form.contact === 'phone'"
          ></b-form-input>
        </template>
        <template v-if="form.contact === 'email'">
          <label class="mt-4">Ваш Email</label>
          <b-form-input
            placeholder="example@mail.ru"
            type="email"
            v-model="form.email"
            :disabled="isLoading"
            :required="form.contact === 'email'"
          ></b-form-input>
        </template>
        <template v-if="form.contact === 'viber'">
          <label class="mt-4">Ваш Viber</label>
          <b-form-input
            placeholder="+375 (__) ___-__-__"
            v-model="form.viber"
            :disabled="isLoading"
            :required="form.contact === 'viber'"
          ></b-form-input>
        </template>
        <template v-if="form.contact === 'telegram'">
          <label class="mt-4">Ваш Telegram</label>
          <b-form-input
            placeholder="Введите ссылку на Telegram"
            v-model="form.telegram"
            :disabled="isLoading"
            :required="form.contact === 'telegram'"
          ></b-form-input>
        </template>
        <template v-if="form.contact == 'instagram'">
          <label class="mt-4">Ссылка на Intagram</label>
          <b-form-input
            placeholder="Ссылка на Intagram"
            v-model="form.instagram"
            :disabled="isLoading"
            :required="form.contact == 'instagram'"
            type="text"
          ></b-form-input>
        </template>
        <template v-if="form.contact === 'another'">
          <label class="mt-4">Другой способ связи</label>
          <b-form-input
            placeholder="Введите другой способ связи"
            v-model="form.another"
            :disabled="isLoading"
            :required="form.contact === 'another'"
          ></b-form-input>
        </template>
        <label class="mt-4">Ваше имя</label>
        <b-form-input
          placeholder="Введите имя"
          required
          v-model="form.name"
        ></b-form-input>
        <div class="mt-4 price">
          <p>
            Примерная стоимость <br /><span>~{{ price }}</span> бел.руб. <br />
            Окончательная стоимость работы устанавливается после консультации.
          </p>
        </div>
        <template v-if="isLoading">
          <div class="flex-center">
            <b-spinner style="width: 40px; height: 40px"></b-spinner>
          </div>
        </template>
        <template v-if="isShowSuccessMessage">
          <div class="flex-center mt-4">
            <h3>Спасибо, ваша заявка принята!</h3>
          </div>
        </template>
        <div class="flex-center">
          <button type="submit" class="btn btn-main" :disabled="isLoading">
            Запросить полную стоимость
          </button>
        </div>
      </b-form>
    </div>
  </b-row>
</template>

<script>
import "./Calculator.scss";
import MainService from "@/services/main-service/main.service";
export default {
  name: "Calculator",
  computed: {
    price: function() {
      let price = 0;
      if (this.form.height == 10) {
        if (this.form.width == 5) {
          price = 240;
        }
        if (this.form.width == 10) {
          price = 360;
        }
        if (this.form.width == 15) {
          price = 540;
        }
        if (this.form.width == 20) {
          price = 720;
        }
        if (this.form.width == 25) {
          price = 1080;
        }
      }
      if (this.form.height == 20) {
        if (this.form.width == 5) {
          price = 660;
        }
        if (this.form.width == 10) {
          price = 720;
        }
        if (this.form.width == 15) {
          price = 1080;
        }
        if (this.form.width == 20) {
          price = 1320;
        }
        if (this.form.width == 25) {
          price = 1560;
        }
      }
      if (this.form.height == 30) {
        if (this.form.width == 5) {
          price = 540;
        }
        if (this.form.width == 10) {
          price = 1200;
        }
        if (this.form.width == 15) {
          price = 1560;
        }
        if (this.form.width == 20) {
          price = 1920;
        }
        if (this.form.width == 25) {
          price = 2160;
        }
      }
      if (this.form.height == 40) {
        if (this.form.width == 5) {
          price = 720;
        }
        if (this.form.width == 10) {
          price = 1320;
        }
        if (this.form.width == 15) {
          price = 1680;
        }
        if (this.form.width == 20) {
          price = 2280;
        }
        if (this.form.width == 25) {
          price = 2880;
        }
      }
      if (this.form.height == 50) {
        if (this.form.width == 5) {
          price = 1080;
        }
        if (this.form.width == 10) {
          price = 1560;
        }
        if (this.form.width == 15) {
          price = 2160;
        }
        if (this.form.width == 20) {
          price = 2880;
        }
        if (this.form.width == 25) {
          price = 3600;
        }
      }
      return price;
    },
  },
  data() {
    return {
      placeOptions: [
        { value: "Шея", text: "Шея" },
        { value: "Плечо", text: "Плечо" },
        { value: "Предплечье", text: "Предплечье" },
        { value: "Кисть", text: "Кисть" },
        { value: "Живот", text: "Живот" },
        { value: "Бедро", text: "Бедро" },
        { value: "Колено", text: "Колено" },
        { value: "Голень", text: "Голень" },
      ],
      contactOptions: [
        { value: "phone", text: "Звонок" },
        { value: "telegram", text: "Telegram" },
        { value: "viber", text: "Viber" },
        { value: "email", text: "Email" },
        { value: "instagram", text: "Instagram" },
        { value: "another", text: "Другое" },
      ],
      isLoading: false,
      isShowSuccessMessage: false,
      form: {
        type: "Заяка на расчет стоимости",
        name: "",
        contact: "phone",
        phone: "",
        telegram: "",
        email: "",
        instagram: "",
        another: "",
        price: 0,
        width: 5,
        height: 10,
        place: "Плечо",
      },
    };
  },
  methods: {
    async submitForm() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.presentLoading();
      try {
        const { status } = await MainService.sendEmail(this.form);
        if (status === "Success") {
          this.showSuccessMessage();
        } else
          this.makeToast(
            "warning",
            "Заяка с сайта!",
            "Упс, что-то пошло не так!"
          );
      } catch (error) {
        console.log(error);
        this.makeToast(
          "error",
          "Заяка с сайта!",
          error.message ?? "Ошибка сервера!"
        );
      } finally {
        this.stopLoading();
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    showSuccessMessage() {
      this.isShowSuccessMessage = true;
      setTimeout(() => {
        this.isShowSuccessMessage = false;
      }, 3000);
    },
    presentLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    makeToast(variant = null, title, content) {
      this.$bvToast.toast(`${content}`, {
        title: `${title}`,
        variant: variant,
        solid: true,
      });
    },
    resetForm() {
      this.form = {
        type: "Заяка на расчет стоимости",
        name: "",
        contact: "phone",
        phone: "",
        email: "",
        telegram: "",
        email: "",
        instagram: "",
        another: "",
        price: 0,
        width: 0,
        height: 0,
        place: "Плечо",
      };
    },
  },
};
</script>
